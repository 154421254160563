import React from 'react';
import { Link } from 'gatsby';

import footerLogo from '../../assets/images/cg-footer-logo.png';

const Footer = ({ footerEmail }) => (
  <footer className="footer">
    <div className="container">
      <div className="footer__inner">
        <div className="footer__links-wrap">
          <a href={`mailto:${footerEmail}`} className="footer__mail-link">
            Connect with me at <span className="footer__mail">{footerEmail}</span>
          </a>
          <div className="footer__privacy">
            Read our&nbsp;
            <Link to="/privacy-policy" className="footer__privacy-link">
              privacy policy
            </Link>
          </div>

          <p className="footer__text">
            © {new Date().getFullYear()} Carolina Gamble. All rights reserved.
          </p>
        </div>
        <img className="footer__logo" src={footerLogo} alt="Caroline Gamble logo" />
      </div>
    </div>
  </footer>
);

export default Footer;
