import React from 'react';

import quoteImg from '../../assets/images/quot-img.jpg';
import quotelogoFirst from '../../assets/images/quot-logo-1.png';
import quotelogoSecond from '../../assets/images/quot-logo-2.png';
import quotelogoThird from '../../assets/images/quot-logo-3.png';

const Quotation = ({ content }) => {
  return (
    <section className="quot">
      <div className="container">
        <div className="quot__inner">
          <div className="quot__img-box">
            <img className="quot__img" src={quoteImg} alt="Caroline Gamble" />
          </div>
          <div className="quot__info">
            <div
              className="quot__info-text"
              dangerouslySetInnerHTML={{
                __html: content.quotationText.childMarkdownRemark.html,
              }}
            />

            <div className="quot__info-logos">
              <img
                className="quot__info-logo quot__info-logo--alliance"
                src={quotelogoFirst}
                alt="Yoga alliance logo"
              />
              <img
                className="quot__info-logo quot__info-logo--teacher"
                src={quotelogoSecond}
                alt="Qualified british wheel of yoga teacher"
              />
              <img
                className="quot__info-logo quot__info-logo--campus"
                src={quotelogoThird}
                alt="Yoga campus"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quotation;
