import React, { useState } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import Modal from 'react-modal';
import Slider from 'react-slick';

import NextArrow from '../../assets/images/breath-comment-slider-next-btn.svg';
import PrevArrow from '../../assets/images/breath-comment-slider-prev-btn.svg';
import CloseLogo from '../../assets/images/card-close.svg';

const CommentBlock = ({ headerContent, cards }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState({});

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      zIndex: 999,
    },
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      display: 'flex',
      border: '0px',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
      background: 'none',
      borderRadius: 'none',
      padding: '20px',
      maxWidth: '678px',
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleCardClick = item => {
    setIsOpen(true);
    setCurrentCard(item);
  };

  const PrevBtn = props => {
    const { className, style, onClick } = props;

    return (
      <button
        className={`${className} comments-slider__btn`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <PrevArrow className="comments-slider__btn-arrow" />
      </button>
    );
  };

  const NextBtn = props => {
    const { className, style, onClick } = props;

    return (
      <button
        className={`${className} comments-slider__btn`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <NextArrow className="comments-slider__btn-arrow" />
      </button>
    );
  };

  const settings = {
    className: 'comments-slider',
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: '0',
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <section className="comments">
      <div className="container">
        <div className="comments__inner">
          <h2 className="comments__title">{headerContent.clientsHeaderTitle}</h2>
          <p className="comments__description">
            {headerContent.clientsHeaderDescription.clientsHeaderDescription}
          </p>

          <Slider {...settings}>
            {cards.map(item => (
              <div
                className="comments-slider__item"
                key={item.id}
                onClick={() => handleCardClick(item)}
              >
                <div className="comments-slider__item-content">
                  <div className="comments-slider__item-info">
                    <img
                      className="comments-slider__item-avatar"
                      src={item.clientAvatar.fluid.src}
                      alt="mock avatar"
                    />
                    <p className="comments-slider__item-author">{item.clientName}</p>
                  </div>
                  <p className="comments-slider__item-text">{item.clientText}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Comment Card Modal"
        style={modalStyles}
        closeTimeoutMS={300}
      >
        {!isEmpty(currentCard) && (
          <div className="card-modal">
            <button className="card-modal__close-btn" onClick={closeModal}>
              <CloseLogo className="card-modal__close-icon" />
            </button>
            <div className="comments-slider__item-content">
              <div className="comments-slider__item-info">
                <img
                  className="comments-slider__item-avatar"
                  src={currentCard.clientAvatar.fluid.src}
                  alt="mock avatar"
                />
                <p className="comments-slider__item-author">{currentCard.clientName}</p>
              </div>
              <p className="comments-slider__item-text">{currentCard.clientText}</p>
            </div>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default CommentBlock;
