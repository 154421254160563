import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import CommentBlock from '../components/CommentBlock';
import Companies from '../components/Companies';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Challenge from '../components/MainSlider';
import ResultBlock from '../components/ResultBlock';
import SEO from '../components/Seo';
import InstaBlock from '../components/InstaBlock';

import '../assets/styles/core.scss';
import Quotation from '../components/Quotation/Quotation';

const IndexPage = () => {
  const {
    pageHeaderContent,
    challengesHeader,
    challengesCards,
    beginHeader,
    beginItems,
    clientsHeader,
    clientsCards,
    instaContent,
    instaCards,
    metaData,
    quotationContent,
    footerEmail,
  } = useStaticQuery(graphql`
    {
      metaData: contentfulPageMetaData {
        metaTitle
        metaDescription {
          metaDescription
        }
      }
      pageHeaderContent: contentfulHeaderContent {
        headerTitle
        headerDescription
        headerSlogan
        headerLargeDesktopBg {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
            ...GatsbyContentfulFluid_withWebp
          }
        }
        headerDesktopBg {
          fluid(quality: 100, maxWidth: 1650) {
            ...GatsbyContentfulFluid
            ...GatsbyContentfulFluid_withWebp
          }
        }
        headerTabletbg {
          fluid(quality: 100, maxWidth: 1024) {
            ...GatsbyContentfulFluid
            ...GatsbyContentfulFluid_withWebp
          }
        }
        headerMobileBg {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyContentfulFluid
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      challengesHeader: contentfulChallengesBlockHeader {
        challengesTitle
        challengesDescription {
          challengesDescription
        }
      }
      challengesCards: allContentfulChallengesSliderCard {
        nodes {
          cardText
          cardTitle
          cardImage {
            fluid(quality: 100, maxHeight: 320, maxWidth: 400) {
              ...GatsbyContentfulFluid
              ...GatsbyContentfulFluid_withWebp
            }
          }
          id
        }
      }

      beginHeader: contentfulBeginHeader {
        beginTitle
        beginDescription
      }

      beginItems: allContentfulBeginBlockItem(sort: { fields: updatedAt, order: DESC }) {
        nodes {
          id
          itemTitle
          itemText {
            childMarkdownRemark {
              html
            }
          }
          itemImage {
            fluid(quality: 100, maxWidth: 448, maxHeight: 627) {
              ...GatsbyContentfulFluid
              ...GatsbyContentfulFluid_withWebp
            }
          }
          itemVideo {
            file {
              url
            }
          }
        }
      }

      clientsHeader: contentfulClientsBlockHeader {
        clientsHeaderTitle
        clientsHeaderDescription {
          clientsHeaderDescription
        }
      }
      clientsCards: allContentfulClientsSliderCard {
        nodes {
          clientText
          clientName
          clientAvatar {
            fluid(quality: 100, maxHeight: 320, maxWidth: 400) {
              ...GatsbyContentfulFluid
              ...GatsbyContentfulFluid_withWebp
            }
          }
          id
        }
      }
      instaContent: contentfulInstagram {
        instagramModalName
        instagramModalTitle
        instagramModalAvatar {
          fluid {
            src
          }
        }
      }
      instaCards: allContentfulInstagramSliderCard {
        nodes {
          instagramPost {
            instagramPost
          }
          instagraImage {
            fluid {
              src
            }
          }
          id
        }
      }

      quotationContent: contentfulQuotation {
        quotationText {
          id
          childMarkdownRemark {
            html
          }
        }
      }

      footerEmail: contentfulFooterEmail {
        email
      }
    }
  `);
  return (
    <>
      <SEO
        title={metaData.metaTitle}
        description={metaData.metaDescription.metaDescription}
      />
      <Header headerContent={pageHeaderContent} />
      <Companies />
      <Challenge textContent={challengesHeader} sliderCards={challengesCards.nodes} />
      <ResultBlock headerContent={beginHeader} resultItems={beginItems.nodes} />
      <CommentBlock headerContent={clientsHeader} cards={clientsCards.nodes} />
      <InstaBlock cards={instaCards.nodes} instaContent={instaContent} />
      <Quotation content={quotationContent} />
      <Footer footerEmail={footerEmail.email} />
    </>
  );
};

export default IndexPage;
