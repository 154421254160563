import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';

import headerLogo from '../../assets/images/cg-header-logo.png';

const Header = ({ headerContent }) => {
  const sources = [
    headerContent.headerLargeDesktopBg.fluid,
    {
      ...headerContent.headerDesktopBg.fluid,
      media: `(max-width: 1700px)`,
    },
    {
      ...headerContent.headerTabletbg.fluid,
      media: `(max-width: 1024px)`,
    },
    {
      ...headerContent.headerMobileBg.fluid,
      media: `(max-width: 550px)`,
    },
  ];

  return (
    <BackgroundImage
      Tag="header"
      className="header"
      fluid={sources}
      backgroundColor="#000000"
    >
      <div className="container">
        <div className="header__inner">
          <img className="header__logo" src={headerLogo} alt="Caroline Gamble logo" />
          <h1 className="header__title">{headerContent.headerTitle}</h1>
          <p className="header__description">{headerContent.headerDescription}</p>
          <p className="header__slogan">{headerContent.headerSlogan}</p>
        </div>
      </div>
    </BackgroundImage>
  );
};

Header.propTypes = {
  headerContent: PropTypes.shape({
    headerDescription: PropTypes.string,
    headerTitle: PropTypes.string,
    headerDesktopBg: PropTypes.shape({}),
    headerMobileBg: PropTypes.shape({}),
  }),
};

export default Header;
