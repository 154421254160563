import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import NextArrow from '../../assets/images/breath-main-slider-next-btn.svg';
import PrevArrow from '../../assets/images/breath-main-slider-prev-btn.svg';

const MainSlider = ({ sliderCards, textContent }) => {
  const NextBtn = props => {
    const { className, style, onClick } = props;

    return (
      <button
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <NextArrow className="main-slider__btn-arrow" />
      </button>
    );
  };

  const PrevBtn = props => {
    const { className, style, onClick } = props;

    return (
      <button
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <PrevArrow className="main-slider__btn-arrow" />
      </button>
    );
  };

  const settings = {
    className: 'center main-slider',
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <section className="practice">
      <div className="container">
        <div className="practice__inner">
          <h2 className="practice__title">{textContent.challengesTitle}</h2>
          <p className="practice__description">
            {textContent.challengesDescription.challengesDescription}
          </p>
          <Slider {...settings}>
            {sliderCards.map(item => {
              const backgroundFluidImageStack = [
                `linear-gradient(180.27deg, rgba(31, 32, 36, 0) -21.66%, rgba(31, 32, 36, 0.21) 23.87%, rgba(31, 32, 36, 0.51) 36.51%, rgba(31, 32, 36, 0.76) 60.54%, #1F2024 96.58%);`,
                item.cardImage.fluid,
              ].reverse();
              return (
                <BackgroundImage
                  Tag="div"
                  className="main-slider__item"
                  fluid={backgroundFluidImageStack}
                  backgroundColor="#000000"
                  key={item.id}
                >
                  <div className="main-slider__gradient" />
                  <div className="main-slider__text-content">
                    <h3 className="main-slider__item-title">{item.cardTitle}</h3>
                    <p className="main-slider__description">{item.cardText}</p>
                  </div>
                </BackgroundImage>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

MainSlider.propTypes = {
  sliderCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cardDescription: PropTypes.shape({}),
      cardTitle: PropTypes.string,
      cardImage: PropTypes.shape({}),
    }),
  ),
  textContent: PropTypes.shape({
    challengesTitle: PropTypes.string,
    challengesDescription: PropTypes.shape({}),
  }),
};

export default MainSlider;
