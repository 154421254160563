import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import telFrame from '../../assets/images/breath-video-frame.png';
import CookieBar from '../CookieBar';
import StoresWrap from '../UIComponents/StoresWrap';

const ResultBlock = ({ headerContent, resultItems }) => {
  return (
    <section className="result">
      <div className="container">
        <div className="result__inner">
          <h2 className="result__title">{headerContent.beginTitle}</h2>
          <span className="result__subtitle">{headerContent.beginDescription}</span>
          <ul className="result__list">
            {resultItems.map((item, index) => (
              <li
                className="result__item"
                key={item.id}
                data-sal="slide-up"
                data-sal-duration="1500"
                data-sal-delay="100"
                data-sal-easing="ease-out-back"
              >
                <div className="result__description-wrap">
                  <h3 className="result__item-title">{item.itemTitle}</h3>
                  <div
                    className="result__item-text"
                    dangerouslySetInnerHTML={{
                      __html: item.itemText.childMarkdownRemark.html,
                    }}
                  />
                  {item.id === '8a96e0e9-309c-5828-a1b1-9548d1dbf57a' && (
                    <a
                      href="https://breathmethod.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="result__item-btn"
                    >
                      book now
                    </a>
                  )}
                  {item.id === '1ff6be3b-4886-5b91-865b-211438732e84' && (
                    <a
                      href="mailto:connect@carolinegamble.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="result__item-btn"
                    >
                      book now
                    </a>
                  )}
                  {item.id === 'ed07ac8c-eff9-5fe1-acf6-0813ffd4ba4e' && <StoresWrap />}
                </div>

                {item.itemVideo?.file?.url ? (
                  <div className="result__video-wrap">
                    <img src={telFrame} className="result__video-frame" alt="tel-frame" />
                    <video
                      src={item.itemVideo.file.url}
                      className="result__video"
                      loop
                      autoPlay
                      playsInline
                      muted
                    />
                  </div>
                ) : (
                  <div className="result__img-wrap">
                    <Img fluid={item.itemImage.fluid} className="result__image" />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <CookieBar />
    </section>
  );
};

ResultBlock.propTypes = {
  headerContent: PropTypes.shape({
    functionalityHeaderTitle: PropTypes.string,
    functionalityHeaderDescription: PropTypes.string,
  }),
  resultItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ResultBlock;
