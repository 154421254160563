import React from 'react';

import BreathLogo from '../../assets/images/breath-method-logo.svg';
import accentureLogo from '../../assets/images/companies-accenture.png';
import audibleLogo from '../../assets/images/companies-audible.png';
import caaLogo from '../../assets/images/companies-caa.png';
import discoverLogo from '../../assets/images/companies-discover.png';
import microsoftLogo from '../../assets/images/companies-microsoft.png';
import pgLogo from '../../assets/images/companies-pg.png';
import thriveLogo from '../../assets/images/companies-thrive-logo.png';
import StoresWrap from '../UIComponents/StoresWrap';

const Companies = () => {
  return (
    <section className="companies">
      <div className="container">
        <div className="companies__inner">
          <div className="companies__founder">
            <h2 className="companies__title companies__title--founder">Founder</h2>
            <BreathLogo className="companies__breath-logo" />
            <StoresWrap />
          </div>
          <div className="companies__coach">
            <h2 className="companies__title companies__title--coach">Breath Coach</h2>
            <img
              className="companies__thrive"
              src={thriveLogo}
              alt="Thrive global logo"
            />
            <div className="companies__logos">
              <img
                className="companies__logo companies__logo--micro"
                src={microsoftLogo}
                alt="Microsoft logo"
              />
              <img
                className="companies__logo companies__logo--accent"
                src={accentureLogo}
                alt="Accenture logo"
              />
              <img
                className="companies__logo companies__logo--audible"
                src={audibleLogo}
                alt="Audible logo"
              />
              <img
                className="companies__logo companies__logo--caa"
                src={caaLogo}
                alt="CAA logo"
              />
              <img
                className="companies__logo companies__logo--discover"
                src={discoverLogo}
                alt="Discover logo"
              />
              <img
                className="companies__logo companies__logo--pg"
                src={pgLogo}
                alt="Procter and gamble logo"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Companies;
